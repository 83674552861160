<template>
  <div class="popup_wrap" style="width:600px;">
    <button class="layer_close" @click="$emit('close')">close</button>

    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">{{ $t('msg.MYIN030T010.017') }}</h1>
      <div class="content_box mt10">
        <!-- content_box -->

        <div class="mile_desc">{{ $t('msg.MYIN030T010.018') }}: <span> {{ sumExMile | comma }}</span>{{ $t('msg.MYIN030T010.019') }}</div>
        <br>
        <div
          id="realgrid2"
          style="width:100%; height:300px;"
        />
        <!-- paging -->
        <div
          v-if="expirelist.length > 0"
          class="paging"
        >
          <a
            v-if="isPaging1"
            class="btn prev"
            @click.prevent="setPrevPage()"
          >Prev</a>
          <a>{{ currentPage + 1 }}/{{ totalPage }}</a>
          <a
            class="btn next"
            @click.prevent="setNextPage()"
          >Next</a>
        </div>
      </div><!-- content_box -->

      <div class="mt10 text_center">
        <a class="button gray lg" href="#" @click="$emit('close')">{{ $t('msg.CSBK140.006') }}</a>
      </div>
    </div><!-- content // -->
  </div><!-- popup_wrap // -->
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import Mileage from '@/api/rest/user/mileage'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  {
    fieldName: 'occrDt',
    dataType: 'text'
  },
  {
    fieldName: 'exprDt',
    dataType: 'text'
  },
  {
    fieldName: 'mlgAmt',
    dataType: 'text'
  }
]

export const columns = [
  {
    name: 'exprDt',
    fieldName: 'exprDt',
    type: 'text',
    width: '100',
    header: {
        text: '소멸일자'
    },
    renderer: 'renderer_date'
  },
  {
    name: 'occrDt',
    fieldName: 'occrDt',
    type: 'datetime',
    width: '100',
    datetimeFormat: 'yyyy-MM-dd',
    header: {
        text: '적립일'
    },
    renderer: 'renderer_date'
  },
  {
    name: 'mlgAmt',
    fieldName: 'mlgAmt',
    type: 'text',
    width: '100',
    header: {
        text: '소멸예정 마일리지'
    },
    renderer: 'renderer_amt'
  }
]

export default {
  name: 'Mileage',
  filters: {
    comma (val) {
      var num = Number(val)
      return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
  props: ['expirelist', 'sumExMile'],
  data: function () {
    return {
      mileageRemainList: [],

      //그리드 변수
      isPaging1: true,
      currentPage: 0,
      totalPage: 0
    }
  },
  computed: {
    getExpireList: function () {
        return this.expirelist ? this.expirelist : ''
    }
  },

  mounted () {
    //리얼그리드 셋팅
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid2')
    gridView.setDataSource(provider)
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.displayOptions.fitStyle = 'fill'
    gridView.displayOptions.rowHeight = 30
    gridView.setEditOptions({
      editable: false,
      updatable: false
    })
    //인디케이터 없애기
    gridView.setRowIndicator({
      visible: false
    })
    //상태바 없애기
    gridView.setStateBar({
      visible: false
    })
    //체크바 없애기
    gridView.setCheckBar({
      visible: false
    })

    //페이징
    gridView.setPaging(true, 3)

    this.setMessageProperty()
  },
  updated () {
    // this.$nextTick(function () { // 모든 화면이 렌더링된 후 실행합니다. });
      this.searchMileageRemain()

      // 그리드 렌더링
      const $vm = this
      $vm.customRenderAmt()
      $vm.customRenderDate()
    // })
  },
  methods: {
    //다국어 적용
    setMessageProperty () {
      // 컬럼 헤더 텍스트 적용
      var haader1 = gridView.getColumnProperty('occrDt', 'header')
      var haader2 = gridView.getColumnProperty('exprDt', 'header')
      var haader3 = gridView.getColumnProperty('mlgAmt', 'header')

      haader1.text = this.$t('msg.MYIN030T010.023')
      haader2.text = this.$t('msg.MYIN030T010.024')
      haader3.text = this.$t('msg.MYIN030T010.017') //this.$t('msg.CSBL260.003')

      gridView.setColumnProperty('exprDt', 'header', haader1)
      gridView.setColumnProperty('occrDt', 'header', haader2)
      gridView.setColumnProperty('mlgAmt', 'header', haader3)
    },
    searchMileageRemain () {
      provider.setRows(this.expirelist)

      //페이징(axios는 비동기 처리기 때문에 promise 처리된 then() 안에서 사용)
      this.currentPage = gridView.getPage() //현재 페이지 반환
      this.totalPage = gridView.getPageCount() //전체페이지 개수 반환

      console.log(' search expiremileage')
    },
    setPrevPage: function () {
      gridView.setPage(this.currentPage - 1)
      this.currentPage = gridView.getPage()
    },
    setNextPage: function () {
      gridView.setPage(this.currentPage + 1)
      this.currentPage = gridView.getPage()
    },
    customRenderDate: function () {
      gridView.registerCustomRenderer('renderer_date', {
        initContent: function (parent) {
          var span = this._span = document.createElement('span')

          parent.appendChild(span)
        },
        render: function (grid, model, width, height, info) {
          info = info || {}
          var span = this._span

          const currDateValue = model.value

          var y = currDateValue.substr(0, 4)
          var m = currDateValue.substr(4, 2)
          var d = currDateValue.substr(6, 2)
          var changeDateValue = y + '-' + m + '-' + d

          span.textContent = changeDateValue
        }
      })
    },
    customRenderAmt: function () {
      gridView.registerCustomRenderer('renderer_amt', {
        initContent: function (parent) {
          var span = this._span = document.createElement('span')

          parent.appendChild(span)
        },
        render: function (grid, model, width, height, info) {
          info = info || {}
          var span = this._span
          var currAmtValue = model.value

          currAmtValue = currAmtValue.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
          span.textContent = currAmtValue
        }
      })
    }
  }

}
</script>

<style>

</style>
