var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "600px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.MYIN030T010.017"))),
        ]),
        _c("div", { staticClass: "content_box mt10" }, [
          _c("div", { staticClass: "mile_desc" }, [
            _vm._v(_vm._s(_vm.$t("msg.MYIN030T010.018")) + ": "),
            _c("span", [_vm._v(" " + _vm._s(_vm._f("comma")(_vm.sumExMile)))]),
            _vm._v(_vm._s(_vm.$t("msg.MYIN030T010.019"))),
          ]),
          _c("br"),
          _c("div", {
            staticStyle: { width: "100%", height: "300px" },
            attrs: { id: "realgrid2" },
          }),
          _vm.expirelist.length > 0
            ? _c("div", { staticClass: "paging" }, [
                _vm.isPaging1
                  ? _c(
                      "a",
                      {
                        staticClass: "btn prev",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.setPrevPage()
                          },
                        },
                      },
                      [_vm._v("Prev")]
                    )
                  : _vm._e(),
                _c("a", [
                  _vm._v(
                    _vm._s(_vm.currentPage + 1) + "/" + _vm._s(_vm.totalPage)
                  ),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "btn next",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setNextPage()
                      },
                    },
                  },
                  [_vm._v("Next")]
                ),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSBK140.006")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }